import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { erc20Abi } from "viem";
import { getBalance, readContract } from "@wagmi/core";
import { config } from "../config";

export type NativeBalance = {
  symbol: string;
  decimals: number;
  value: ethers.BigNumberish;
  formatted: string;
};

export interface Balances {
  native: NativeBalance | null;
  tokens: { [tokenAddress: string]: ethers.BigNumberish };
}

export function useEthereumBalances(
  address: string | null,
  tokenAddresses: string[],
) {
  const [balances, setBalances] = useState<Balances>({
    native: null,
    tokens: {},
  });

  useEffect(() => {
    const fetchBalances = async (e?: any) => {
      if (address) {
        try {
          const nativeBalance = await getBalance(config, {
            // @ts-ignore
            address,
          });
          const tokens: {
            [tokenAddress: string]: ethers.BigNumberish;
          } = {};

          for (const tokenAddress of tokenAddresses) {
            tokens[tokenAddress] = await readContract(config, {
              abi: erc20Abi,
              // @ts-ignore
              address: tokenAddress,
              functionName: "balanceOf",
              // @ts-ignore
              args: [address],
              // @ts-ignore
              account: address,
            });
          }

          setBalances({
            native: nativeBalance,
            tokens,
          });
          if (e) {
            e.detail();
          }
        } catch (error) {
          console.error("Failed to fetch Ethereum balances:", error);
        }
      } else {
        setBalances({ native: null, tokens: {} });
      }
    };

    fetchBalances();

    const intervalId = setInterval(fetchBalances, 10000); // fetch every 10 seconds

    window.addEventListener("update-chain-balances", fetchBalances);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("update-chain-balances", fetchBalances);
    };
  }, [address, tokenAddresses.length]);

  return balances;
}
