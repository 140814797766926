import { createConfig, http } from "@wagmi/core";
import {
  arbitrum,
  avalanche,
  base,
  fantom,
  mainnet,
  optimism,
  polygon,
  sepolia,
} from "wagmi/chains";

export const config = createConfig({
  chains: [
    mainnet,
    avalanche,
    polygon,
    fantom,
    arbitrum,
    optimism,
    base,
    sepolia,
  ],
  transports: {
    [mainnet.id]: http(),
    [avalanche.id]: http(),
    [polygon.id]: http(),
    [fantom.id]: http(),
    [arbitrum.id]: http(),
    [optimism.id]: http(),
    [base.id]: http(),
    [sepolia.id]: http(),
  },
});
