import React from "react";
import Home from "./Home";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PopupProvider } from "react-popup-manager";
import { WagmiProvider } from "wagmi";
import { config } from "../config";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <PopupProvider>
          <div>
            <Home />
          </div>
        </PopupProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
