// src/components/ConnectWallet.tsx
import React, { useRef, useState } from "react";
import { useChainId, useDisconnect, useSwitchChain } from "wagmi";
import { injected } from "wagmi/connectors";
import { useConnect } from "wagmi";
import { useAccount } from "wagmi";
import Button from "../Button";
import { shortenAddress } from "../../util/helpers";
import SignIn from "../SignIn";
import css from "./ConnectWallet.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  logout,
  setAuthData,
  setConnectLoading,
} from "../../features/authSlice";
import { SettingIcon } from "../../assets";
import useOutsideClick from "../../hooks/useOutsideClick";
import { LS_AUTH_KEY } from "../../util/constants";
import Select from "../Select";
import { useBreakpoint } from "../../hooks/useBreakpoints";
import CopyToClipboard from "../CopyToClipboard";
import { config } from "../../config";

const ConnectWallet: React.FC = () => {
  const [state, setState] = React.useState<{
    address?: string;
    error?: Error;
    loading?: boolean;
  }>({});
  const { isConnected, address } = useAccount();
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const settingRef = useRef(null);
  const { isMobile } = useBreakpoint();

  useOutsideClick(settingRef, contextMenuOpen, () => setContextMenuOpen(false));

  const { chains, switchChain } = useSwitchChain();

  const chainId = useChainId();

  const dispatch = useAppDispatch();
  const { address: stateAddress, connectLoading } = useAppSelector(
    (state) => state.auth,
  );

  const { connect } = useConnect({ config });
  const { disconnect } = useDisconnect();

  const handleConnect = async () => {
    try {
      dispatch(setConnectLoading(true));
      connect({ connector: injected() });
      dispatch(setConnectLoading(false));
    } catch (error) {
      console.error("Failed to connect to wallet:", error);
    }
  };

  const handleDisconnect = () => {
    disconnect();
    dispatch(logout());
  };

  const handleLoggedOut = () => {
    dispatch(logout());
  };

  const handleLoggedIn = ({
    address,
    token,
  }: {
    address: string;
    token: string;
  }) => {
    localStorage.setItem(LS_AUTH_KEY, JSON.stringify({ address, token }));
    setState((x) => ({ ...x, address, token }));
    dispatch(setAuthData({ address, token }));
  };

  return (
    <div className={css.buttonContainer}>
      {isConnected ? (
        <>
          <Select
            placeholder={"Chain"}
            options={chains.map((chain) => ({
              label: chain.name,
              value: chain.id,
            }))}
            value={chainId}
            onChange={(id) =>
              switchChain({
                chainId: id,
              })
            }
          />
          {stateAddress && (
            <div className={css.relative}>
              <CopyToClipboard text={stateAddress}>
                <Button>
                  Signed in as {shortenAddress(stateAddress)}
                  <p className={css.subtext}>Click to copy address</p>
                </Button>
              </CopyToClipboard>
            </div>
          )}
          {!stateAddress && !isMobile && (
            <SignIn
              onSuccess={handleLoggedIn}
              onError={({ error }) => setState((x) => ({ ...x, error }))}
            />
          )}
          {!stateAddress && address && (
            <Button>{shortenAddress(address)}</Button>
          )}
          <div className={css.contextMenuContainer} ref={settingRef}>
            <button
              type="button"
              onClick={() => setContextMenuOpen(!contextMenuOpen)}
            >
              <SettingIcon />
            </button>
            {contextMenuOpen && (
              <div className={css.contextMenu}>
                <button
                  onClick={handleDisconnect}
                  className={css.contextMenuItem}
                >
                  Disconnect
                </button>
                {stateAddress && (
                  <button
                    onClick={handleLoggedOut}
                    className={css.contextMenuItem}
                  >
                    Log out
                  </button>
                )}
                {!stateAddress && isMobile && (
                  <SignIn
                    onSuccess={handleLoggedIn}
                    onError={({ error }) => setState((x) => ({ ...x, error }))}
                    className={css.contextMenuItem}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <Button onClick={handleConnect}>
          {connectLoading ? "Connecting..." : "Connect Wallet"}
        </Button>
      )}
    </div>
  );
};

export default ConnectWallet;
