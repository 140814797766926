import React, { useMemo, useState } from "react";
import { increaseBalance, increaseBalanceMsg } from "../../api/backendApi";
import { useAccount, useSignTypedData } from "wagmi";
import { usePopupManager } from "react-popup-manager";
import { splitSignature } from "ethers/lib/utils.js";
import { AxiosError } from "axios";
import css from "./Forms.module.scss";
import Select from "../Select";
import Input from "../Input";
import { SubmitButton } from "../Button";
import Modal from "../Modal";
import { Token } from "../../constants";
import { notification } from "antd";
import { InfoIcon } from "../../assets";
import { DepositDescription } from "./Descriptions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToQueue } from "../../features/queueSlice";
import { switchChain } from "@wagmi/core";
import { config } from "../../config";

interface DepositFormProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const DepositForm: React.FC<DepositFormProps> = (props) => {
  const { address } = useAccount();

  const [token, setToken] = useState<Token>({} as Token);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [chain, setChain] = useState<
    1 | 10 | 43114 | 137 | 250 | 42161 | 8453 | 11155111
  >(1); // New state
  const { signTypedDataAsync } = useSignTypedData();
  const popupManager = usePopupManager();
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");

  const tokens = useAppSelector((state) => state.tokens.tokens);

  const chainInfo = useMemo(
    () => Object.values(tokens).find((token) => token.chainId == chain),
    [tokens, chain],
  );

  const tokenOptions = useMemo(
    () =>
      Object.values(tokens)
        .find((token) => token.chainId === chain)
        ?.tokens.filter((token) => token.actions.includes("increase"))
        .map((token) => ({
          label: token.symbol,
          value: { ...token, key: token.address },
        })) || [],
    [chain, tokens],
  );

  const chainOptions = useMemo(
    () =>
      Object.values(tokens)
        .filter((c) => c.tokens.some((t) => t.actions.includes("increase")))
        .map((info) => ({
          label: info.name,
          value: info.chainId,
        })),
    [tokens],
  );

  const handleTokenChange = (value: Token) => {
    setToken(value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue)) {
      setAmount(inputValue);
      setError("");
    } else {
      if (inputValue.includes("-")) {
        setError("Invalid input. Negative numbers are not allowed");
      } else if (inputValue.includes(",")) {
        setError("Invalid input. Use . for decimal point");
      } else {
        setError("Invalid input. Only [0-9] and . are allowed");
      }
    }
  };

  const handleChainChange = (value: string) => {
    // @ts-ignore
    setChain(parseInt(value));
    setToken({} as Token);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await switchChain(config, { chainId: chain });
      const value = BigInt(+amount * 10 ** token.decimals); // decimals?

      if (chainInfo) {
        const { message, domain, types } = await increaseBalanceMsg({
          owner: address!,
          tokenAddress: token.address,
          chain: chainInfo?.key,
          amount: value.toString(),
        });

        const sig = await signTypedDataAsync({
          domain,
          types,
          message: {
            owner: message.owner as `0x${string}`,
            spender: message.spender as `0x${string}`,
            value: BigInt(message.value),
            nonce: BigInt(message.nonce),
            deadline: BigInt(message.deadline),
          },
          primaryType: "Permit",
        });
        const { v, r, s } = splitSignature(sig);

        // Call the deposit function from your backendApi here
        const buq = await increaseBalance({
          address: address!,
          tokenAddress: token.address,
          chain: chainInfo.key,
          owner: message.owner,
          spender: message.spender,
          value: String(value),
          deadline: Number(message.deadline),
          v,
          r,
          s,
        });
        console.log("Deposit successful", buq);
        if (buq?.id) {
          dispatch(addToQueue(buq.id));
        }

        notification.success({ message: "Success!" });

        if (props.onClose) {
          props.onClose();
        }
      }
    } catch (error) {
      console.error("Deposit failed:", error);

      if (error instanceof AxiosError) {
        return notification.error({
          message:
            typeof error?.response?.data.message === "string"
              ? error?.response?.data.message
              : error?.response?.data.message.body,
        });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const showDescription = () => {
    popupManager.open(DepositDescription);
  };

  return (
    <Modal {...props}>
      <div className={css.formWrapper}>
        <h2 className={css.title}>
          Deposit
          <button type="button" onClick={showDescription}>
            <InfoIcon />
          </button>
        </h2>
        <form onSubmit={handleSubmit} className={css.form}>
          <div className={`${css.twoCol} ${css.mb12}`}>
            <Select
              onChange={handleChainChange}
              value={chain}
              placeholder="Select Chain"
              options={chainOptions}
            />
            <Select
              onChange={handleTokenChange}
              value={token}
              disabled={!chain}
              placeholder="Select Token"
              options={tokenOptions}
            />
          </div>
          <Input
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            suffix={token.symbol}
            className={css.mb12}
          />
          {error && (
            <p className={`${css.errorMessage} ${css.mb12}`}>{error}</p>
          )}
          <div className={css.submitButtonContainer}>
            <SubmitButton
              disabled={loading || !amount || !chain || !token.address}
            >
              {loading ? "Loading..." : "Deposit"}
            </SubmitButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DepositForm;
